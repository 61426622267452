<template>
	<div
		class="full-height"
	>
		<div
			class="full-height flex-column overflow-y-auto mb-10"
		>
			<div
				class="justify-space-between"
			>
				<div class="flex-1 bg-white pa-10 mr-10">
					<h6 class="under-line-identify">계정정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>영업단 구분 <span class="color-red">*</span></th>
							<td
								v-if="codes.A001"
							>
								<template
									v-for="(code, index) in codes.A001.items"
								>
									<button
										v-if="index > (user.role == 'admin' ? 0 : 1) && index < 3"
										:key="code.total_code"

										class="pa-5-10"
										:class="item.agency_type == code.total_code ? 'bg-green' : 'bg-default'"

										@click="item.agency_type = code.total_code; onAgencyType()"
									>{{ code.code_name }}</button>
								</template>

							</td>
							<th  @click="onAgencyType">소속 영업점 <span class="color-red">*</span> <v-icon v-if="is_search_upper" class="color-green size-px-20" >mdi mdi-card-search</v-icon></th>
							<td>
								{{ text_upper }}
							</td>
						</tr>
						<tr>
							<th>아이디 <span class="color-red">*</span></th>
							<td>
								<input
									v-model="item.account_id"
									class="input-box full-width"
									placeholder="아이디를 입력하세요"
									:rules="[$rules.id(item, 'account_id', { min: 4, max: 20})]"
								/>

								<button
									v-if="false"
									class="pa-5-10 bg-blue-light "
								>중복 체크</button>
							</td>
							<th>등록일</th>
							<td class="position-relative">
								<input
									v-model="item.join_date"
									class="input-box full-width"
									placeholder="등록일을 선택하세요"
									readonly
									@click="is_data_pick = !is_data_pick"
								/>
								<v-date-picker
									v-if="is_data_pick"
									v-model="item.join_date"
									no-title
									scrollable
									class="position-absolute box"
									style="top: 45px; left: -5px"
									@change="is_data_pick = false"
								></v-date-picker>
							</td>
						</tr>
						<tr>
							<th>상호명 <span class="color-red">*</span></th>
							<td>
								<input
									type="text"
									v-model="item.account_name"
									maxlength="20"
									class="input-box full-width"
									placeholder="상호명을 입력하세요"
								/>
							</td>
							<th>휴대폰 번호 <span class="color-red">*</span></th>
							<td>
								<input
									type="number"
									v-model="item.account_phone_number"
									:ruels="[rules.max(item, 'account_phone_number', 12)]"
									class="input-box full-width"
									placeholder="휴대폰번호를 입력하세요"
								/>
							</td>
						</tr>
						</tbody>
					</table>


					<div class="mt-10">
						<h6>정산 정보</h6>
						<table class="table th-left td-left">

							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr
								v-if="item.agency_type == 'A001001'"
							>
								<th> {{ text_fee_rate }} <span class="color-red">*</span></th>
								<td>
									<div
										class="justify-start"
									>
										<div class="flex-1 text-left justify-space-between">
											카드 결제
											<span>
											<input
												v-model="item.sales_fee"
												type="number"
												:rules="[rules.demical(item, 'sales_fee', { min: 2, max: 2})]"
												class="box pa-5-10 width-fee "
											/> %
												</span>
										</div>
									</div>
									<div
										class="justify-start mt-10"
									>
										<div class="flex-1 text-left justify-space-between">
											무통장 입금
											<span>
											<input
												v-model="item.sales_fee_bank"
												type="number"
												:rules="[rules.demical(item, 'sales_fee_bank', { min: 2, max: 2})]"
												class="box pa-5-10 width-fee "
											/> %
												</span>
										</div>
									</div>
								</td>
								<th>정산주기</th>
								<td class="text-left">영업일 기준 / 월 정산</td>
							</tr>
							<tr
								v-else
							>
								<th>정산주기</th>
								<td class="text-left" colspan="3">영업일 기준 / 월 정산</td>
							</tr>
							<tr>
								<th>은행</th>
								<td>
									<select
										v-model="item.bank_code"
										class="input-box"
									>
										<option :value="''">은행을 선택하세요</option>
										<option
											v-for="code in codes.B001.items"
											:key="code.total_code"
											:value="code.total_code"
										>{{ code.code_name}}</option>
									</select>
								</td>
								<th>계좌번호</th>
								<td>
									<input
										v-model="item.bank_account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'bank_account', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>
									<input
										v-model="item.bank_holder"
										class="input-box full-width"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일</th>
								<td>
									<input
										v-model="item.bank_email"
										type="email"
										class="input-box full-width"
										placeholder="세금계산서 발행 이메일"
										maxlength="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="flex-1 bg-white pa-10">
					<h6 class="under-line-identify">상점 정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr
							v-if="false && item.agency_type == 'A001003'"
						>
							<th>표기 구분 <span class="color-red">*</span></th>
							<td colspan="3">

								<button
									v-for="(summary, s_index) in items_summary"
									:key="'summary_' + s_index"
									class="btn-inline"
									:class="summary == item.shop_summary ? 'bg-green': 'bg-default'"
									@click="item.shop_summary = summary"
								>{{  summary }}</button>

							</td>
						</tr>
						<tr>
							<th>서비스 구분 <span class="color-red">*</span></th>
							<td colspan="3">
								<span v-if="!item.agency_upper" class="color-red">상위 영업점을 선택하세요</span>

								<template
									v-else
								>
									<button
										v-if="is_on_wallet"
										class="mr-10"
										@click="is_wallet = !is_wallet"
									>
										<v-icon
											v-if="is_wallet"
											class="color-blue"
										>mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
										지갑
									</button>
									<button
										v-if="is_on_api"
										class="mr-10"
										@click="is_api = !is_api"
									>
										<v-icon
											v-if="is_api"
											class="color-blue"
										>mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
										API
									</button>
									<button
										v-if="is_on_payment"
										@click="is_payment = !is_payment; !is_payment ? is_self = false : ''"
									>
										<v-icon
											v-if="is_payment"
											class="color-blue"
										>mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
										수기결제
									</button>

									<button
										v-if="is_on_payment"
										@click="is_self = !is_self"
										:disabled="!is_payment"
									>
										<v-icon
											v-if="is_self"
											class="color-blue"
										>mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
										카드 인증
									</button>
								</template>
							</td>
						</tr>
						<tr>
							<th>서비스명 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="text"
									v-model="item.shop_name"
									maxlength="50"
									class="input-box-inline"
									placeholder="서비스명을 입력하세요"
								/>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001003'"
						>
							<th>상점 로고</th>
							<td colspan="3">
								<div>
									<label
										class="box pa-10 justify-space-between"
									>
										{{ logo_img_name }}
										<v-icon
											class="color-icon"
										>mdi mdi-image</v-icon>

										<input-file
											v-show="false"
											accept="image/*" @change="setFile2"
										/>
									</label>
								</div>

								<div
									v-if="item_upload_logo_img.src"
									class=" mt-10"
								>
									<div
										class="" style="position: relative"
									>
										<img
											:src="item_upload_logo_img.src"
											style="max-width: 180px; object-fit: cover"
										/>
										<button class="item_close" style="background-color: black">
											<v-icon
												@click="removeDelivery"
											>mdi-close</v-icon>
										</button>
									</div>
								</div>
								<div class="mt-10">
									<p>{{  item_upload_logo_img.name }}</p>
								</div>
							</td>
						</tr>
						<tr>
							<th>사업자 구분</th>
							<td colspan="3">
								<button
									v-for="code in codes.B002.items"
									:key="code.total_code"

									class="pa-5-10"
									:class="item.business_type == code.total_code ? 'bg-green' : 'bg-default'"

									@click="item.business_type = code.total_code"
								>{{ code.code_name }}</button>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002002'"
						>
							<th>사업자등록번호</th>
							<td colspan="3">
								<input
									type="number"
									v-model="item.front"
									:ruels="[rules.max(item, 'front', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002001'"
						>
							<th>사업자등록번호</th>
							<td>
								<input
									type="number"
									v-model="item.front"
									:ruels="[rules.max(item, 'front', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
							<th>법인등록번호</th>
							<td>
								<input
									type="number"
									v-model="item.back"
									:ruels="[rules.max(item, 'back', 13)]"
									class="input-box-inline"
									placeholder="법인 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002003'"
						>
							<th>주민등록 번호</th>
							<td colspan="3">
								<input
									v-model="item.front"
									type="number"
									:ruels="[rules.max(item, 'front', 6)]"
									class="input-box-inline mr-10"
									placeholder="주민등록 앞번호"
								/>
								<input
									v-model="item.back"
									type="password"
									maxlength="7"
									class="input-box-inline"
									placeholder="주민등록 뒷번호"
								/>
							</td>
						</tr>
						<tr>
							<th>사업장 주소</th>
							<td colspan="3">

								<input
									v-model="item.shop_zip_code"

									class="input-box-inline mr-10"
									type="text" placeholder="우편번호"
									readonly

									@click="daumPost('company')"
								>

								<button
									class="box pa-5-10 bg-identify"
									@click="daumPost('company')"
								>주소 검색</button>

								<input
									v-model="item.shop_address"

									class="input-box full-width mt-10" type="text" placeholder="주소"
									readonly

									@click="daumPost('company')"
								>
								<input
									v-model="item.shop_address_detail"

									class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
									maxlength="50"
								>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001002'"
						>
							<th>가상계좌</th>
							<td>
								<select
									v-model="item.virtual_uid"
									class="input-box"
								>
									<option :value="''">가상계좌를 선택하세요</option>
									<option
										v-for="(virtual, v_index) in items_virtual"
										:key="'virtual_' + v_index"
										:value="virtual.uid"
									>{{ virtual.virtual_name}}</option>
								</select>
							</td>
							<th>SMS</th>
							<td>
								<select
									v-model="item.sms_uid"
									class="input-box"
								>
									<option value="">선택하세요</option>
									<option
										v-for="(sms, sms_index) in items_sms"
										:key="'sms_' + sms_index"
										:value="sms.uid"
									>{{ sms.sms_name}}</option>
								</select>
							</td>
						</tr>

						<tr
							v-if="false"
						>
							<th>결제 MID</th>
							<td>
								<input
									type="text"
									v-model="item.member_id"
									maxlength="20"
									class="input-box full-width"
									placeholder="결제 MID를 입력하세요"
								/>
							</td>
							<th></th>
							<td></td>
						</tr>

						<tr
							v-if="item.agency_type == 'A001003'"
						>
							<th>연결 도메인</th>
							<td colspan="3">

								<input
									v-model="item.shop_link"
									class="input-box full-width"
									placeholder="도메인을 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>1회 출금 한도 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="number"
									v-model="item.payment_limit"
									maxlength="20"
									class="input-box-inline mr-10"
									placeholder="1회 출금 한도"
									:rules="[rules.max(item, 'payment_limit', 9)]"
								/>
								<button
									class="mr-10"
									@click="item.is_payment_limit = item.is_payment_limit == 0 ? 1 : 0"
								>
									<v-icon
										v-if="item.is_payment_limit > 0"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									출금 한도 적용
								</button>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div
			class="mt-auto justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="box pa-10-20"
				@click="toList"
			>목록</button>
		</div>
		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_bussiness"
			:option="modal_option"
			:width="'560px'"
			:height="'480px'"

			@cancel="is_bussiness = false"
			@close="is_bussiness = false"
			@click="setUpper"
		>
			<div
				slot="modal-content"
			>
				<div>
					<input
						v-model="item_search.agency_name"
						class="input-box-inline mr-10"
						placeholder="영업점 명을 입력하세요"
					/>
					<button
						@click="getUpper"
						class="box pa-5-10 bg-base"
					>조회</button>
				</div>
				<table
					class="table mt-10"
				>
					<thead>
					<tr>
						<th>구분</th>
						<th>영업점 명</th>
						<th>선택</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(upper, index) in items_upper"
						:key="'upper_' + index"
						:class="{on: upper.uid == item_upper_select.uid}"
					>
						<td>{{ upper.agency_type_name}}</td>
						<td class="text-left">{{ upper.agency_name}}</td>
						<td><button @click="selectUpper(upper)" class="btn-blue pa-5-10">선택</button></td>
					</tr>
					</tbody>
				</table>
			</div>
		</Modal>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";

import Modal from "../../components/Modal";
import InputFile from "../../components/InputFile";
export default {
	name: 'AgencyItem'
	, components: {InputFile, Modal, DaumPost}
	, props: ['Axios', 'user', 'codes', 'rules', 'date']
	, data: function(){
		return {
			program: {
				name: '영업점 등록'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				agency_type: this.$route.params.type ? this.$route.params.type : 'A001003'
				, business_type: 'B002001'
				, bank_code: ''
				, join_date: this.date.getToday('-')
				, agency_upper: this.user.admin_id
				, sales_fee: 0.5
				, sales_fee_bank: 0
				, pg_type: 'P001001'
				, sales_pg_uid: null
				, pg_code: ''
				, agency_upper_name: ''
				, sales_type: ''
				, virtual_uid: ''
				, sms_uid: ''
				, is_payment_limit: 1
				, payment_limit: 3000000
				, shop_summary: '가맹점'
			}
			, is_wallet: false
			, is_payment: false
			, is_self: false
			, is_api: false
			, is_manage: false
			,is_data_pick: false
			,is_modal: false
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,items_upper: []
			, items_pg_list: []
			, is_bussiness: false
			, items_distributor: []
			, modal_option: {
				top: true
				,title: '소속 영업점 조회'
				,bottom: true
			}
			, item_upper_company: {
				agency_upper: ''
				, agency_name: ''
			}
			, item_upper_distributor: {
				agency_upper: ''
				, agency_name: ''
			}
			, item_search: {
				agency_name: ''
			}
			, item_upper: {
				uid: ''
				, agency_name: ''
			}
			, item_upper_select: {

			}
			, items_sms: []
			, items_virtual: []
			, item_logo_img: ''
			, item_upload_logo_img: {}
			, items_summary: this.codes.shop_summary
		}
	}
	,computed: {

		logo_img_name: function(){
			let name = '로고 이미지'
			return name
		}
		, text_fee_rate: function(){
			let t = '공급가 마진'
			if(this.item.agency_type == 'A001003'){
				t = '공급가 마진'
			}
			return t
		}
		, text_upper: function(){
			let t = ''

			if(this.user.role == 'admin'){

				if(this.item_upper.uid){
					t = this.item_upper.agency_name
				}
			}else{
				t = this.user.account_name
			}

			return t
		}
		, is_search_upper: function(){
			let t = false
			if(this.user.role == 'admin' && this.item.agency_type != 'A001001'){
				t = true
			}
			return t
		}
		, sales_type: function(){
			let t = 0

			if(this.is_wallet){
				t += 1
			}
			if(this.is_payment){
				t += 10
			}
			if(this.is_self){
				t += 100
			}
			if(this.is_manage){
				t += 1000
			}
			if(this.is_api){
				t += 10000
			}

			return t
		}
		, is_on_wallet: function(){
			let t = false
			if( (this.item_upper.sales_type && this.$common.getPowNumber(this.item_upper.sales_type, 1))){
				t = true
			}
			return t
		}
		, is_on_payment: function(){
			let t = false
			if((this.item_upper.sales_type && this.$common.getPowNumber(this.item_upper.sales_type, 2))){
				t = true
			}
			return t
		}
		, is_on_api: function(){
			let t = false
			if(this.item.agency_type != 'A001003' || (this.item_upper.sales_type && this.$common.getPowNumber(this.item_upper.sales_type, 5))){
				t = true
			}
			return t
		}
	}
	, methods: {
		save: async function(){
			try{
				this.$bus.$emit('on', true)

				if(this.item_logo_img){
					this.$set(this.item, 'item_logo_img', this.item_logo_img)
				}

				this.$set(this.item, 'sales_pg_list', JSON.stringify(this.items_pg_list))
				this.item.sales_type = this.sales_type

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgency'
					,data: this.item
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.toList()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'shop_zip_code', call.zonecode)
			this.$set(this.item, 'shop_address', call.address)

			this.is_post = false
		}
		, toList: function(){
			this.$router.back()
		}
		, getUpper: async function(){
			try{
				this.item_upper_select = {}
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getUpper'
					,data: {
						agency_type: this.item.agency_type
						, agency_name: this.item_search.agency_name
					}
				})
				if(result.success){
					this.items_upper = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setUpper: function(){
			this.item_upper = this.item_upper_select
			this.item.agency_upper = this.item_upper.uid
			this.is_bussiness = false
		}
		, selectUpper: function(upper){
			this.item_upper_select = upper
			this.is_wallet = this.$common.getPowNumber(upper.sales_type, 1)
			this.is_payment = this.$common.getPowNumber(upper.sales_type, 2)
			this.is_self = this.$common.getPowNumber(upper.sales_type, 3)
			this.is_api = this.$common.getPowNumber(upper.sales_type, 4)
		}
		, onAgencyType: function(){

			if(this.user.role != 'admin'){
				return false
			}
			this.item_upper = {}

			if(this.item.agency_type == 'A001001'){
				this.item.agency_upper = this.user.admin_id
			}else{
				this.item.agency_upper = ''
				this.is_bussiness = true
			}

			this.items_upper = []
		}
		, getSmsList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getSmsList'
					, data: {
						agency_type: this.item.agency_type
					}
				})
				if (result.success) {
					this.items_sms = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
						agency_type: this.item.agency_type
					}
				})
				if (result.success) {
					this.items_virtual = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, setFile2: function(e){
			console.log('setFile2', e)

			let self = this
			for(let file of e){
				console.log(`file` , file)
				this.item_logo_img = file

				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = function(e){
					console.log('reader.onload')
					data.src = e.target.result
					self.item_upload_logo_img = data
				}

				reader.readAsDataURL(file)
			}
		}
		, removeDelivery: async function(){
			if(this.item_logo_img){

				if(this.item.shop_logo){
					this.item_upload_logo_img = {
						src: this.item.shop_logo
						, name: this.item.shop_logo
						, type: 'image'
					}
					this.item_logo_img = {}
				}else{
					this.item_upload_logo_img = {}
					this.item_logo_img = {}
				}
			}else{
				if(confirm("삭제하시겠습니까?")){

					try{
						const result = await this.$request.init({
							method: 'post'
							,url: 'management/postShopLogoDelete'
							,data: {
								uid: this.item.uid
							}
						})

						if(result.success){
							this.item_upload_logo_img = {}
							this.item_logo_img = {}
						}else{
							this.$bus.$emit('notify', { type: 'error', message: result.message })
						}
					}catch (e) {
						console.log(e)
					}
				}
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		if(this.user.role_group == 'agency'){
			this.item.agency_type = this.codes.A001.items[2].total_code
		}
		if(this.user.role == 'distributor'){
			this.item.agency_upper = this.user.agnecy_uid
		}
		this.getSmsList();
		this.getVirtualList();
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-btn__content { color: #333 !important;}

.v-file-input__text--placeholder {
	color: #bbb !important;
	font-size: 14px;
}

.theme--light.v-icon {
	color: #bbb;
}

.item_close {
	position: absolute; right: 10px; top: 10px
}
</style>